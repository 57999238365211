<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
        <v-row no-gutters>
          <v-col cols="11" md="4">
            <v-text-field
              outlined
              dense
              v-model="schoolDataTable[$API_KEYS.SEARCH]"
              label="Search by School, District or Sales Rep"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.xsOnly && this.adminAccess==='Super Admin'"
            class=""
            align-self="center"
            cols="1"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item
                    @click="
                      toggleAddEditViewSchoolModal({
                        show: true,
                        type: 'add',
                      })
                    "
                  >
                    <v-list-item-title> Add School </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="route">
                    <v-list-item-title> Bulk Import </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-col
            class="text-right d-flex"
            v-if="
              $vuetify.breakpoint.smAndUp &&
              this.layout === 'AdminDashboardLayout' && this.adminAccess==='Super Admin'
            "
            cols="4"
            md="5"
            xl="3"
          >
            <v-btn
              text
              style="border: 1px solid #38227a; border-radius: 10px;width:130px"
              class="text-capitalize mr-5 px-12"
              height="45px"
              @click="route"
            >
              <span style="color: #38227a">bulk import</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewSchoolModal({
                  show: true,
                  type: 'add',
                })
              "
              class="text-capitalize px-12"
              height="45px"
              style="border-radius: 10px;width:130px"
            >
              <span>Add School</span>
            </v-btn>
          </v-col>
        </v-row>
    <div class="table-div">
      <v-data-table
        :options.sync="dataOptions"
        hide-default-footer
        :headers="schoolDataTable.headers"
        :items="schoolDataTable.items"
        :loading="schoolDataTable.loading"
        loading-text="Loading... Please wait"
        class="DataTable elevation-1"
        mobile-breakpoint="0"
        :items-per-page="
          schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]"
      >
        <template v-slot:item="row">
          <tr height="80px" class="text-flex">
            <td class="text-center row-item px-0">
              <v-row class="justify-center">
                <v-col  cols="10" md="12" lg="11" xl="8">
                  <div class="d-flex align-center" style="">
                    <div v-if="row.item.logo_url">
                      <img width="45" :src="row.item.logo_url" />
                    </div>
                    <div v-else>
                      <v-avatar color="#F9B95A">
                        <span class="user-initials-text">{{
                          row.item.school_name.substring(0, 2).toUpperCase()
                        }}</span>
                      </v-avatar>
                    </div>
                    <div class="ml-2">
                      <div>
                        <div
                          class="text-left font-weight-bold"
                          style="font-size: 14px"
                        >
                          {{ row.item.school_name }}
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-left"
                          style="font-size: 12px; color: grey"
                        >
                          {{ row.item.district_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>

            <td class="text-center row-item py-2">
              <v-row justify="center">
                <v-col cols="10">
                  <div class="text-left">
                    <div style="font-weight: 600">{{ row.item.user_name }}</div>
                    <div style="font-size: 12px; color: grey">
                      {{ row.item.user_email }}
                    </div>
                    <div style="font-size: 12px; color: grey">
                      {{ row.item.user_phone }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>

            <td class="text-center row-item px-0">
              {{ row.item.city_name }}/{{ row.item.state_name }}
            </td>
            <td class="text-center row-item">{{ row.item.lead_group }}</td>
            <td class="text-center row-item">{{ row.item.sales_rep_name }}</td>
            <td class="text-center row-item">
              <tr>
                <td>
                  <v-chip
                    color="#F2F2F2"
                    class="actionColumn-btn"
                    @click="
                      viewPublicDonationPageHandler(
                        row.item.campaign_detail,
                        row.item.id
                      )
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Public Page</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    @click="viewSchoolCampaign(row.item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Dashboard</span>
                    </v-tooltip>
                  </v-chip>
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="#F2F2F2"
                        class="actionColumn-btn"
                        v-bind="attrs"
                        v-on="on"
                        v-if="layout === 'AdminDashboardLayout'"
                      >
                        <v-icon color="#6B6B6B" size="25"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          style="min-height: 40px"
                          @click="
                            toggleAddEditViewSchoolModal({
                              show: true,
                              type: 'edit',
                              id: row.item.id,
                            })
                          "
                        v-if="row.item.user_type == 'admin'||'Sales Representative'||'Event Support Staff'">
                          <v-icon left size="18">mdi-pencil</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Edit School
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="startNewCampaignHandler(row.item)"
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                       v-if="row.item.user_type == 'admin'||'Sales Representative'||'Event Support Staff'" >
                          <v-img
                            src="@/assets/menuCampaignLogo.png"
                            width="17px"
                            class="mr-2"
                          ></v-img>
                          <v-list-item-title style="font-size: 14px">
                            Start a New Campaign
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="viewCampaignHandler(row.item)"
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                        >
                          <v-img
                            src="@/assets/menuCampaignViewLogo.svg"
                            width="17px"
                            class="mr-2"
                          ></v-img>
                          <v-list-item-title style="font-size: 14px">
                            View Campaigns
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="schoolDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="
                    justify-center justify-md-start
                    d-flex
                    px-5
                    tableHeader-text
                  "
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPageList"
                      hide-details
                      v-model="
                        schoolDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      schoolDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      schoolDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="schoolDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="
                justify-center justify-md-start
                d-flex
                px-5
                tableHeader-text
              "
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPageList"
                  hide-details
                  v-model="
                    schoolDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{ schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-view-school-modal
      :SchoolFormData="SchoolFormData"
      @reload="getSchoolManagementData()"
    ></add-edit-view-school-modal>
  </div>
</template>
<script>
import { ITEMS, LOADING, SALES_REP, SCHOOL } from "@/constants/ModuleKeys";
import {
  DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  SCHOOL_LIST,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_TYPE,
  ID,
  HOST_NAME,
  CAMPAIGN_ID,
  HOST_EMAIL,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import { API_ADMIN_SCHOOL_MANAGEMENT_LIST } from "@/constants/APIUrls";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
import Helper from "@/helper";
export default {
  name: "SchoolManagement",
  components: {
    AddEditViewSchoolModal: () =>
      import("./AddEditViewSchoolModal/AddEditViewSchoolModal.vue"),
  },
  data() {
    return {
     adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      schoolDataTable: {
        headers: [
          { width: "15%",
            text: "School Info",
            align: "center",
            value: "school_name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          { width: "15%",
            text: "Contact Person Info",
            align: "center",
            value: "contact_person",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          { width: "15%",
            text: "City/State",
            align: "center",
            value: "city",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          { width: "15%",
            text: "The Lead Group",
            align: "center",
            value: "lead_group",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "15%",
            text: "Sales REP",
            value: "sales_rep",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "40%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
      },
      SchoolFormData: {
        flag: false,
        loading: false,
        disabled: false,
        type: "add",
        item: [],
      },
      itemsPerPageList: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "schoolDataTable.page_info.page_number": function () {
      this.getSchoolManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "schoolDataTable.page_info.detail_count": function () {
      this.getSchoolManagementData();
    },
    //Watcher for detecting change in SEARCH
    "schoolDataTable.search": function () {
      this.debounceGetSchoolManagementData();
    },
    showModal: function (value) {
      console.log(value);
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.schoolDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getSchoolManagementData();
      } else {
        this.schoolDataTable[SORTING_KEY] = "";
        this.getSchoolManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // district Modal
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    route() {
      // this.$router.push({
      //   name: ROUTER_URL.adminPanel.children.bulkUploadSchool.name,
      // });
      this.$root.$refs.adminLayout.route("school");
    },
    getSchoolManagementData() {
      const self = this;
      this.schoolDataTable.loading = true;
      console.log("inside getSchooolManagementData");
      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "SCHOOL_LIST: ",
          res[DATA][SCHOOL_LIST]
        );
        self.schoolDataTable.items = res[DATA][SCHOOL_LIST];
        self.schoolDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.schoolDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.schoolDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.schoolDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.schoolDataTable[SEARCH];
      dataTableParams[ITEMS_PER_PAGE] =
        this.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      if (this.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.schoolDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.schoolDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] =  this.schoolDataTable[SORTING_KEY];
      if (this.layout === "CampaignDashboardLayout") {
        if (this.userType === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        }
      }

      Axios.request_GET(
        API_ADMIN_SCHOOL_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    startNewCampaignHandler(campaign) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query:{type:"add"},
        params: {
          [CAMPAIGN_TYPE]: "school",
          [SALES_REP]: campaign[SALES_REP],
          [ID]: campaign[ID],
          [HOST_NAME]: campaign[HOST_NAME],
          [HOST_EMAIL]: campaign[HOST_EMAIL],
        },
      });
    },
    viewSchoolCampaign(school) {
      const { id, campaign_detail } = school;
      if (campaign_detail) {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.schoolPanel.children.dashboard.name,
          query: {
            [SCHOOL]: id,
            [CAMPAIGN_ID]: campaign_detail.id,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.showToast({
          message: "This school isn't associated with any campaigns yet",
          color: "e",
        });
      }
    },
    viewCampaignHandler(campaign) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.campaignManagement.name,
        params: { search: campaign.school_name },
      });
    },
    setFormDataParam() {
      if (this.$route.params[ID]) {
        this.toggleAddEditViewSchoolModal({
          show: true,
          type: "add",
        });
      }
    },
    viewPublicDonationPageHandler(item, id) {
      if (item) {
        const { sub_domain, campaign_type } = item;
        if (campaign_type === "school") {
          Helper.openPublicDonationPage(sub_domain);
        } else {
          const queryParam = "school=" + id;
          Helper.openPublicDonationPage(sub_domain, queryParam);
        }
      } else {
        this.showToast({
          message: "This school isn't associated with any campaigns yet",
          color: "e",
        });
      }
    },
  },
  mounted() {
    this.getSchoolManagementData();
    console.log(this.layout);
    this.setFormDataParam();
  },
  created() {
    this.debounceGetSchoolManagementData = _.debounce(
      this.getSchoolManagementData,
      500
    );
  },
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
 
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  font-family: Lato;
}
.v-btn >>> {
  letter-spacing: 0px;
  font-weight: 600;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
}
.v-list.menu-list {
  border: 1px solid #d2d2d2;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.v-menu__content.theme--light.menuable__content__active {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 45px;
  }
  .v-input.search-input {
    width: 240px;
  }
  .v-icon.notranslate.mobile-menu {
    position: relative;
    bottom: 13px;
  }
  .v-data-table-header {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
  .table-div {
    overflow-x: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1100px;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
